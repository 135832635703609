.details-width {
    width: 100px
}

.Profile-details {
    text-align: start;
    margin-bottom: 40px;
    padding-left: 70px
}

.Profile-card-bottom {
    padding: 0 20px 20px
}

.Profile-back-cover img {
    width: 100%;
    border-radius: 20px;
    height: 186px
}

.Profile-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 10px solid #fff;
    position: absolute;
    left: 50%;
    top: 170px;
    transform: translateX(-50%)
}

.Profile-card {
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    border: 1px solid #eaeaea
}

.profile-show-mobile {
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    border: 1px solid #eaeaea;
    max-width: 450px;
}

.Profile-show-mobile.Profile-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 10px solid #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -77%
}

.Profile-back-cover {
    margin-bottom: 50px;
    padding: 24px
}

.Profile-name h4 {
    font-size: 20px;
    font-weight: 700
}

.profile-btn {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around
}

.profile-btn button.btn {
    font-size: 14px;
    font-weight: 700;
    padding: 11px 16px;
    width: 190px;
    background-color: #f2f2f2
}

.profile-btn {
    margin: 40px 0
}

.profile-btn .button.btn span {
    font-size: 20px;
    padding-right: 8px
}

.social-images-icon img {
    width: 35px;
    height: 35px
}

.bottom-discription p {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 800
}

.Profile-details h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px
}

.Profile-details p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px
}

.profile-btn button.btn span {
    font-size: 20px;
    padding-right: 10px
}

.custum-modal-body {
    padding: 50px !important
}

.modal-header {
    background: #DEDEDE;
    padding: 24px 40px;
    border-radius: 25px 25px 0 0
}

.modal-title.h4 {
    font-size: 20px;
    font-weight: 800
}

.Cancel-bttn {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: red;
    width: 200px;
    padding: 12px 0
}

.Cancel-bttn:hover {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: red;
    width: 200px;
    padding: 12px 0
}

.Active-bttn {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #75B317;
    width: 200px;
    padding: 12px 0;
    margin-right: 8px
}

.Active-bttn:hover {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #75B317;
    width: 200px;
    padding: 12px 0;
    margin-right: 8px
}

.modal-btn {
    display: flex;
    justify-content: center
}

.profile-bottom-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media only screen and (min-width:1200px) and (max-width:1740px) {
    .profile-btn {
        display: block;
        width: 100%
    }

    .profile-btn button.btn {
        width: 87%;
        margin: 0 10px 20px
    }

    .Profile-details {
        text-align: start;
        margin-bottom: 40px;
        padding-left: 25px
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .custum-modal-body {
        padding: 40px 20px !important
    }

    .Profile-back-cover {
        margin-bottom: 50px;
        padding: 12px
    }

    .Profile-img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 10px solid #fff;
        position: absolute;
        left: 50%;
        top: 130px;
        transform: translateX(-50%)
    }

    .Profile-details {
        text-align: start;
        margin-bottom: 40px;
        padding-left: 40px;
    }
}

@media only screen and (min-width:280px) and (max-width:350px) {
    .profile-btn button.btn {
        font-size: 13px;
        font-weight: 700;
        padding: 11px 16px;
        background-color: #f2f2f2;
        width: auto
    }
}

@media only screen and (min-width:351px) and (max-width:768px) {
    .profile-btn button.btn {
        font-size: 13px;
        font-weight: 700;
        padding: 11px 16px;
        background-color: #f2f2f2;
        width: 150px
    }
}

@media (max-width: 478.98px) {
    .Profile-details{
        padding-left: 16px !important;
    }
}