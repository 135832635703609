.housecleaning-hero {
    background-image: url(../Images/bg-image/housecleaning.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.housecleaning-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.filtter-list {
    display: flex
}

.filtter-list .filtter-list-item a.nav-link {
    width: fit-content !important;
    background: transparent;
    color: #B6B6B6;
    font-size: 18px;
    font-weight: 500
}

.filtter-list .filtter-list-item a.nav-link.active {
    width: fit-content !important
}

.windowcleaning-hero {
    background-image: url(../Images/bg-image/windowcleaning.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.windowcleaning-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.windowcleaning-comtent-title .title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px
}

.windowcleaning-comtent {
    margin-bottom: 40px
}

.icon-warper {
    display: flex;
    align-items: top;
    margin-bottom: 24px !important
}

.icon-warper .windowcleaning-icon {
    color: #75B317;
    margin-right: 6px
}

.icon-warper .windowcleaning-icon svg {
    width: 14px;
    height: auto
}

.cleaningtip-hero {
    background-image: url(../Images/bg-image/cleaningtip.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.cleaningtip-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.service-list-box-inner {
    background: #fff
}

.service-list-box-image img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.service-list-box-warpper {
    padding: 20px
}

.service-list-box-content .title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #75b317
}

.service-list-box-content .description {
    font-size: 14px;
    margin-bottom: 20px
}

.service-list-box-button .custom-btn {
    color: #fff;
    border: 3px solid #75B317;
    background-image: -webkit-linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-image: linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    padding: 10px 20px;
    border-radius: 0;
    font-size: 14px;
    font-weight: 600
}

.service-list-box-button .custom-btn:hover {
    background-position: 100%;
    color: #75B317
}

.service-list-box-button .custom-btn2 {
    background: #75b317;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 17px 20px;
    border-radius: 0
}

.initalcleaning-hero {
    background-image: url(../Images/bg-image/initialcleaning.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.initalcleaning-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.scheduled-hero {
    background-image: url(../Images/bg-image/scheduled.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.scheduled-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.windowcleaning-comtent-text .sub-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px
}

.lamase-hero {
    background-image: url(../Images/bg-image/lamesa.webp);
    background-position: top;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.lamase-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.icon-warper .action-email {
    color: #75B317;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500
}

.action-email {
    color: #75B317;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500
}

span.windowcleaning-bold-text {
    font-weight: 700;
    color: #75b317
}

.sandiego-hero {
    background-image: url(../Images/bg-image/sandiego.webp);
    background-position: top;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.sandiego-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.checklist-hero {
    background-image: url(../Images/bg-image/checklist.webp);
    background-position: top;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.checklist-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.service-list-box {
    margin-bottom: 22px
}

@media (max-width:767px) {
    .housecleaning-hero {
        background-position: center;
        height: 350px
    }

    .bg-custom-title .title {
        font-size: 38px;
        font-weight: 500;
        text-align: center;
        color: #fff;
        margin-bottom: 0
    }

    .windowcleaning-comtent-title .title {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 20px
    }

    .windowcleaning-hero {
        background-position: center;
        height: 350px
    }

    .cleaningtip-hero {
        background-position: center;
        height: 350px
    }

    .initalcleaning-hero {
        background-position: center;
        height: 300px
    }

    .scheduled-hero {
        background-position: center;
        height: 300px
    }

    .lamase-hero {
        background-position: center;
        height: 300px
    }

    .sandiego-hero {
        background-position: center;
        height: 300px
    }

    .checklist-hero {
        background-position: center;
        height: 300px
    }
}