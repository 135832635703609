.navbar {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.075) !important;
}

.navbar {
    position: fixed;
    width: 100%;
    z-index: 100;
    padding: 0px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    margin-bottom: 0px;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.custom-header-nav-menu {
    display: flex;
    gap: 20px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
    margin-bottom: 0 !important;
    align-items: center;
}

.nav-links {
    color: #2c2c2c !important;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    transition: all 0.2s ease-out;
    color: #75b317;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.header-dropdwn button.dropdown-toggle {
    padding: 0px 5px;
    background-color: transparent !important;
    border: none;
    font-size: 16px;
    font-weight: 700;
}

.header-dropdwn {
    padding: 8px 10px !important;
    border-radius: 4px;
    background-color: transparent;
}

.header-dropdwn:hover {
    transition: all 0.2s ease-out;
}

a.dropdown-item.hover-new:hover {
    color: #75b317;
    background-color: transparent;
}

.header-dropdwn:hover button.dropdown-toggle {
    color: #75b317;
}

.header-login-btn {
    background: transparent !important;
    border: none;
    font-size: 17px;
    font-weight: 700;
    box-shadow: none !important;
}

.header-login-btn:hover {
    color: #000;
    transition: all 0.2s ease-out;
    background: transparent !important;
}

.home-drop-show {
    transform: translate(0px, 46px) !important;
}

.custom-header-nav-menu li.active a {
    color: #75b317;
}

.cstm-navbar .custom-header-nav-menu li {
    height: 70px;
    align-items: center;
    display: flex;
    border-bottom: 3px solid transparent;
}

.cstm-navbar .custom-header-nav-menu li:hover, .cstm-navbar .custom-header-nav-menu li.active {
    border-bottom: 3px solid #75b317;
}

@media (max-width: 991.98px) {
    nav.navbar {
        position: fixed;
        width: 100%;
        height: 70px;
        z-index: 9999999;
    }

    /* .header-dropdwn{
        padding: 0px !important;
    } */

    .cstm-navbar .custom-header-nav-menu li{
        height: 46px;
    }

    .header-dropdwn button.dropdown-toggle{
        padding: 0px ;
    }

    .cstm-navbar .custom-header-nav-menu li:hover, .cstm-navbar .custom-header-nav-menu li.active {
        border-bottom: 3px solid transparent;
    }

    .custom-header-nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 100%;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        gap: 5px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .custom-header-nav-menu.active {
        background: #fff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: left;
        padding: 1rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #7577fa;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(18%, 30%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 30%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #6568f4;
        transition: 250ms;
    }
}

@media only screen and (min-width: 280px) and (max-width: 425px) {
    .modal-body {
        padding: 22px !important;
    }
}