body {
    background-color: #fbfbfb;
    color: #2C2C2C;
    font-family: 'Barlow', sans-serif
}

.hero-section {
    background-image: url(../../assets/Images/hero/home-bg.webp);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    align-items: center
}

.hero-title {
    font-size: 62px;
    font-weight: 500;
    margin-bottom: 60px;
    color: #fff
}

.hero-title .bold-title {
    font-weight: 900
}

button.btn.hero-btn {
    color: #75B317;
    border: 3px solid #fff;
    background-image: -webkit-linear-gradient(30deg, #fff 50%, transparent 50%);
    background-image: linear-gradient(30deg, #fff 50%, transparent 50%);
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    padding: 20px 60px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600
}

button.btn.hero-btn:hover {
    background-position: 100%;
    color: #fff;
}

.custom-section {
    padding: 120px 0 0
}

.custom-section-mr {
    margin: 120px 0 0
}

.custom-title .title {
    font-size: 70px;
    font-weight: 500;
    color: #2C2C2C;
    margin-bottom: 60px
}

.custom-title .bold-title {
    font-weight: 900
}

.review-section {
    background-image: url(../../assets/Images/bg-image/bg02.webp);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.review-link {
    background-color: #75B317;
    padding: 24px 28px
}

.review-link {
    margin-bottom: 22px
}

.review-link .review-link-btn {
    font-size: 27px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 0;
    text-decoration: none
}

.review-btn-img {
    width: 50px;
    height: auto;
    margin-right: 20px
}

.review-btn {
    display: flex;
    justify-content: end;
    margin-bottom: 40px
}

/* .review-btn .review-link-btn {
    background-color: #75B317
} */

/* .review-btn .review-link-btn {
    background-color: #75B317;
    color: #fff;
    padding: 20px 40px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600
} */

.review-btn .review-link-btn {
    color: #fff;
    border: 3px solid #75B317;
    background-image: -webkit-linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-image: linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    padding: 20px 40px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600
}

.review-btn .review-link-btn:hover {
    background-position: 100%;
    color: #75B317;
}

.custom-hover-effect {
    color: #fff;
    border: 3px solid #75B317 !important;
    background-image: -webkit-linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-image: linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    padding: 20px 40px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600
}

.custom-hover-effect:hover {
    background-position: 100%;
    color: #75B317;
}

.profile02-image {
    max-width: 700px;
    height: auto
}

.about-us-title h3 {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 32px
}

.about-us-content p {
    font-size: 18px;
    margin-bottom: 35px
}

/* .about-us-btn a.about-us-link-btn {
    background-color: #75B317;
    color: #fff;
    padding: 20px 40px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600
} */

.our-blog-title h3 {
    font-size: 26px
}

.our-blog-content p {
    font-size: 18px;
    margin-bottom: 35px
}

.our-blog-btn .our-blog-link-btn {
    color: #fff;
    border: 3px solid #75B317 !important;
    background-image: -webkit-linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-image: linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    padding: 18px 40px;
    border-radius: 0;
    font-size: 16px;
    font-weight: 600
}

.our-blog-btn .our-blog-link-btn:hover {
    background-position: 100%;
    color: #75B317;
}

.blog-img {
    height: auto
}

.cstm-padding {
    padding: 0 40px 40px 40px
}

.ourblog-btn .ourblog-link-btn {
    color: #fff;
    border: 3px solid #75B317 !important;
    background-image: -webkit-linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-image: linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    padding: 16px 30px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600
}

.ourblog-btn .ourblog-link-btn:hover {
    background-position: 100%;
    color: #75B317;
}

.social-custom-effect {
    text-decoration: none;
}

.Right-bar-content-header h3 {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #030014;
}

.Right-bar-content-Footer h3 {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 30px;
    color: #030014
}

.Right-bar-content-body-button .Rightbar-bg-color {
    background-color: #030014;
    color: #fff
}

.Right-bar-content-body-button a {
    text-decoration: none;
    color: #fff
}

.Right-bar-content-body-button a .Rightbar-icon {
    font-size: 30px;
    margin: 0 15px;
    color: #75B317
}

.Rightbar-text p {
    margin-bottom: 0;
    font-size: 14px
}

.Rightbar-text h4 {
    margin-bottom: 0;
    font-size: 20px
}

.Right-bar-content-body-form .form-control {
    border: 1px solid #75b317
}

.Right-bar-content-body-form {
    padding: 26px 16px;
    border: 2px solid #75b317;
    background: #fff;
}

.rightbar-submite-btn {
    background: #75b317 !important;
    color: #fff !important;
    width: 100%;
    height: 50px;
    font-weight: 600;
    font-size: 18px
}

.rightbar-submite-btn:hover {
    background: transparent !important;
    color: #75B317 !important;
    width: 100%;
    height: 50px;
    font-weight: 600;
    font-size: 18px;
    border: 2px solid #75b317;
    transition: 0.2s;
}

.testimonial {
    display: flex;
    text-align: center
}

.testimonial .Rating-image {
    margin-bottom: 40px
}

.testimonial-title h2 {
    font-size: 45px;
    font-weight: 400
}

.testimonial-description p {
    font-size: 26px;
    margin-bottom: 100px
}

.user-comment {
    border: 4px solid #75b317
}

.user-comment .user-img {
    position: relative;
    top: -60px;
    left: 20px
}

.user-comment .user-img img {
    width: 80px;
    border-radius: 50%;
    height: 80px
}

.user-comment {
    text-align: left;
    padding: 20px
}

.user-comment .user-name h3 {
    font-size: 22px;
    color: #75b317
}

.user-comment-description p {
    font-size: 18px
}

.user-comment-description p span {
    color: #75B317;
    font-weight: 600
}

.comment-icon {
    color: #75b317;
    font-size: 26px;
    margin-right: 20px;
    margin-left: auto
}

.review-hero {
    background-image: url(../Images/bg-image/review.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.review-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.blog-short {
    display: flex;
    flex-direction: row;
}

.hero-section-mobile {
    background-color: #75B317
}

.profile02-image {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.tab-content-img img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.bg-custom-title .title {
    font-size: 70px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    margin-bottom: 60px
}

.tab-service-title {
    font-size: 40px;
    color: #2c2c2c;
    font-weight: 700;
    margin-bottom: 20px;
}

.tab-content-img-text {
    margin-top: 20px;
}

.user-comment {
    text-align: left;
    padding: 20px;
    height: 100%;
}

@media (max-width:767px) {
    .testimonial-title h2 {
        font-size: 34px;
        font-weight: 400
    }

    .testimonial-description p {
        font-size: 18px;
        margin-bottom: 60px
    }

    .user-comment {
        text-align: left;
        padding: 20px;
        margin-bottom: 60px
    }
}

@media (max-width:767px) {
    .review-hero {
        background-position: center;
        height: 300px
    }

    .about-image-content {
        display: flex;
        align-items: center;
        justify-content: center
    }

    .product-img {
        height: 100%;
        width: 70% !important;
        background-image: url(../../assets/Images/bg-image/vector.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain
    }
}

@media only screen and (min-width:1200px) and (max-width:1740px) {

    .ourblog-btn .ourblog-link-btn {
        color: #fff;
        border: 3px solid #75B317 !important;
        background-image: linear-gradient(30deg, #75B317 50%, transparent 50%);
        background-size: 600px;
        background-repeat: no-repeat;
        background-position: 0%;
        transition: background 300ms ease-in-out;
        padding: 16px 18px;
        border-radius: 0;
        font-size: 18px;
        font-weight: 600;
    }

    .cstm-padding {
        padding: 0 12px 40px 12px ;
    }

    .Profile-back-cover {
        margin-bottom: 50px;
        padding: 40px 20px;
    }

}