.mobile-show {
    display: none
}

@media only screen and (min-width:320px) and (max-width:992px) {
    .custom-section-mr {
        margin: 60px 0 0 !important;
    }
 }

@media only screen and (min-width:320px) and (max-width:767px) {
    .hero-title {
        font-size: 10vw;
        font-weight: 500;
        margin-bottom: 32px;
        text-align: center;
        line-height: 1.24;
    }

    
}

@media only screen and (min-width:576px) and (max-width:767px) {
    .hero-title {
        font-size: 50px;
    }
}


@media only screen and (min-width:768px) and (max-width:991px) {
    .hero-title {
        font-size: 65px;
    }

    .hero-section-mobile {
        background-color: #75B317;
        padding: 195px 0 0px;
    }
}

@media only screen and (min-width:280px) and (max-width:992px) {
    .custom-container {
        margin: 0 15px !important
    }

    .mobile-show {
        display: block
    }

    .mobile-hide {
        display: none
    }

    .tab-content-img img {
        width: 100%;
        height: auto
    }

    .profile02-image {
        max-width: 100%;
        height: auto
    }

    .service-section {
        display: none
    }

    .hero-image img {
        width: 100%;
        height: auto
    }

    .slider-content {
        background: #fff;
        padding: 10px 10px;
        margin-left: 0;
        margin-bottom: 16px
    }

    .slider-text p {
        font-size: 12px;
        font-weight: 500
    }

    p.user-name {
        font-size: 16px;
        font-weight: 700
    }

    img.user-image {
        width: 88px;
        border-radius: 50%;
        height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: none
    }

    .hero-text {
        text-align: center
    }

    .custom-section {
        padding: 40px 0 0
    }

    .socialicon {
        display: none
    }
}

@media only screen and (min-width:280px) and (max-width:403px) {
    .blog-short {
        display: inline-block
    }

    .cstm-padding {
        padding: 20px 0 20px
    }

    .blog-img {
        max-width: 100%;
        width: 100%;
        height: 200px
    }

    .blog-short .ms-4 {
        margin-left: 0px !important
    }

    .our-blog-title {
        margin-top: 16px
    }

    .ourblog-btn .ourblog-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 14px 28px;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600;
        transition: 0.4s;
        width: 100%
    }

    .custum-modal-body {
        padding: 40px 20px !important
    }
}

@media only screen and (min-width:404px) and (max-width:767px) {
    .blog-short {
        display: flex
    }

    .cstm-padding {
        padding: 20px 0 20px
    }

    .blog-img {
        max-width: 100%;
        width: 100%;
        height: auto
    }

    .blog-short .ms-4 {
        margin-left: 16px !important
    }

    .our-blog-title {
        margin-top: 0
    }

    .ourblog-btn .ourblog-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 10px 18px;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600;
        transition: 0.4s;
        width: 100%
    }

    .our-blog-title h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 18px
    }

    .our-blog-content p {
        font-size: 14px;
        margin-bottom: 16px
    }

    .custum-modal-body {
        padding: 40px 20px !important
    }
}

@media only screen and (min-width:280px) and (max-width:425px) {
    .hero-section-mobile {
        background-color: #75B317;
        padding: 150px 0 0
    }


    button.btn.hero-btn {
        border: 3px solid #fff;
        color: #fff;
        border-radius: 0;
        padding: 12px 26px;
        font-size: 18px;
        background: transparent
    }

    .custom-title .title {
        font-size: 39px;
        font-weight: 500;
        color: #2C2C2C;
        margin-bottom: 40px;
        text-align: center
    }

    .review-link {
        background: #75B317;
        padding: 12px;
        margin-bottom: 1.5rem
    }

    .review-btn .review-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 12px 24px;
        border-radius: 0;
        font-size: 15px;
        font-weight: 600
    }

    .review-link .review-link-btn {
        font-size: 20px;
        font-weight: 800;
        color: #fff;
        margin-bottom: 0;
        text-decoration: none
    }

    .review-btn {
        display: flex;
        justify-content: end;
        margin: 20px 0 40px 0
    }

    .our-blog-btn .our-blog-link-btn {
        background-color: transparent;
        padding: 14px 28px;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        border: 2px solid #75B317;
        transition: 0.4s
    }

    .about-us-title h3 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 18px
    }

    .about-us-content p {
        font-size: 14px;
        margin-bottom: 30px
    }

    .about-us-btn a.about-us-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 14px 28px;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600
    }
}

@media only screen and (min-width:426px) and (max-width:520px) {
    .hero-section-mobile {
        background-color: #75B317;
        padding: 150px 0 0
    }



    button.btn.hero-btn {
        border: 3px solid #fff;
        color: #fff;
        border-radius: 0;
        padding: 16px 32px;
        font-size: 18px;
        background: transparent
    }

    .custom-title .title {
        font-size: 39px;
        font-weight: 500;
        color: #2C2C2C;
        margin-bottom: 40px;
        text-align: center
    }

    .review-link {
        background: #75B317;
        padding: 12px;
        margin-bottom: 1.5rem
    }

    .review-btn .review-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 12px 24px;
        border-radius: 0;
        font-size: 15px;
        font-weight: 600
    }

    .review-link .review-link-btn {
        font-size: 20px;
        font-weight: 800;
        color: #fff;
        margin-bottom: 0;
        text-decoration: none
    }

    .review-btn {
        display: flex;
        justify-content: end;
        margin: 20px 0 40px 0
    }

    

    .about-us-title h3 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 18px
    }

    .about-us-content p {
        font-size: 14px;
        margin-bottom: 30px
    }

    .about-us-btn a.about-us-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 14px 28px;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600
    }

    .our-blog-title h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 18px
    }

    .our-blog-content p {
        font-size: 14px;
        margin-bottom: 30px
    }

    .blog-short {
        display: flex;
    }

    .cstm-padding {
        padding: 20px 0 20px
    }

    .blog-img {
        max-width: 90%;
        width: 100%;
        height: auto
    }

    .blog-short .ms-4 {
        margin-left: 0px !important
    }
}

@media only screen and (min-width:521px) and (max-width:767px) {
    .hero-section-mobile {
        background-color: #75B317;
        padding: 110px 0 0
    }


    .review-link {
        background: #75B317;
        padding: 22px;
        margin-bottom: 1.5rem
    }

    .slider-content {
        background: #fff;
        padding: 12px 16px;
        margin-left: 0;
        margin-bottom: 16px
    }

    p.user-name {
        font-size: 18px;
        font-weight: 700
    }

    .cstm-padding {
        padding: 0 40px 40px 0
    }

    .our-blog-btn {
        margin-bottom: 40px
    }

    .custom-section {
        padding: 50px 0 0
    }

    .custom-title .title {
        font-size: 60px;
        font-weight: 500;
        color: #2C2C2C;
        margin-bottom: 60px
    }

    .hero-section-mobile {
        background-color: #75B317;
        padding: 150px 0 0;
    }
}

@media only screen and (min-width:768px) and (max-width:884px) {
    
    .custom-title .title {
        font-size: 60px;
        font-weight: 500;
        color: #2C2C2C;
        margin-bottom: 60px
    }

    .review-btn .review-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 18px 36px;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600
    }

    .review-link {
        background: #75B317;
        padding: 12px 8px;
        margin-bottom: 12px
    }

    .review-link .review-link-btn {
        font-size: 20px;
        font-weight: 800;
        color: #fff;
        margin-bottom: 0;
        text-decoration: none
    }

    .review-btn-img {
        width: 27px;
        height: auto;
        margin-right: 10px
    }

    .about-us-title h3 {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 32px
    }

    .about-us-content p {
        font-size: 16px;
        margin-bottom: 35px
    }

    .about-us-btn a.about-us-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 18px 36px;
        border-radius: 0;
        font-size: 18px;
        font-weight: 600
    }

    .blog-img {
        max-width: 100%;
        height: 200px;
        width: 100%
    }


    .cstm-padding {
        padding: 30px 0px 10px 0px;
    }

    .ourblog-btn .ourblog-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 14px 28px;
        border-radius: 0;
        font-size: 18px;
        font-weight: 600;
        transition: 0.4s
    }

    .our-blog-content p {
        font-size: 18px;
        margin-bottom: 18px
    }

    /* .blog-short .ms-4 {
        margin-left: 0px !important
    } */
}

@media only screen and (min-width:885px) and (max-width:991px) {
    .review-link {
        background: #75B317;
        padding: 12px 11px;
        margin-bottom: 12px
    }

    .review-link .review-link-btn {
        font-size: 22px;
        font-weight: 800;
        color: #fff;
        margin-bottom: 0;
        text-decoration: none
    }

    .review-btn-img {
        width: 40px;
        height: auto;
        margin-right: 10px
    }

    .about-us-title h3 {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 32px
    }

    .about-us-content p {
        font-size: 16px;
        margin-bottom: 35px
    }

    .about-us-btn a.about-us-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 18px 36px;
        border-radius: 0;
        font-size: 18px;
        font-weight: 600
    }

    .blog-img {
        max-width: 100%;
        height: 200px;
        width: 100%
    }

    .blog-short {
        display: block
    }

    .cstm-padding {
        padding: 0 0 30px
    }

    .ourblog-btn .ourblog-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 14px 28px;
        border-radius: 0;
        font-size: 18px;
        font-weight: 600;
        transition: 0.4s
    }

    .our-blog-content p {
        font-size: 18px;
        margin-bottom: 18px
    }

    .blog-short .ms-4 {
        margin-left: 0px !important
    }
}

@media only screen and (min-width:1024px) and (max-width:1300px) {
    .custom-container {
        margin: 0 60px
    }

    .custom-title .title {
        font-size: 60px
    }

    .review-btn .review-link-btn {
        background-color: #75B317;
        color: #fff;
        padding: 18px 36px;
        border-radius: 0;
        font-size: 18px;
        font-weight: 600
    }

    .review-link {
        background: #75B317;
        padding: 18px 16px;
        margin-bottom: 16px
    }

    .review-link .review-link-btn {
        font-size: 20px
    }

    .review-btn-img {
        width: 40px;
        height: auto;
        margin-right: 10px
    }

    .tab-content-img img {
        width: 100%;
        height: auto
    }

    .profile02-image {
        width: 100%;
        height: auto
    }

    .custom-section {
        padding: 90px 0 0
    }

    .our-blog-content p {
        font-size: 18px;
        margin-bottom: 12px
    }

    .cstm-padding {
        padding: 0 0 40px 0
    }
}

@media only screen and (min-width:1301px) and (max-width:1450px) {
    .custom-container {
        margin: 0 130px
    }

    .review-link {
        background: #75B317;
        padding: 18px 22px;
        margin-bottom: 16px
    }
}

@media only screen and (min-width:992px) and (max-width:1300px) {
    .our-blog-content p {
        font-size: 18px;
        margin-bottom: 12px;
    }
 }