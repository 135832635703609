.Topnavigation-container {
    padding-top: 84px
}

.notification-btn {
    height: 32px;
    width: 32px;
    padding: 2px;
    font-size: 18px;
    background-color: #75b317;
    border-radius: 50%;
    color: #fff
}

.icn-width {
    width: 18px;
    height: auto
}

.Topnavigation-container .Topnavigation-containt-header h1 {
    font-size: 28px;
    font-weight: 600;
    color: #2c2c2c
}

.Topnavigation-container .Topnavigation-containt-header img {
    width: 24px;
    height: auto
}

.Loged-in-user .navbar-collapse .nav-item.dropdown {
    font-size: 14px;
    font-weight: 400
}

.Loged-in-user {
    padding: 0
}

.Loged-in-user .navbar-collapse .nav-item.dropdown a.dropdown-toggle.nav-link {
    padding-left: 4px !important
}

.dashboard-header-bg {
    background-color: #eaeaea;
    margin: 0 30px;
    position: fixed;
    z-index: 999;
    width: -webkit-fill-available;
}

.dashboard-custom-container {
    margin: 0 30px
}

.dashboard-custom-container .nav-menu .dropdown-toggle {
    color: #2C2C2C;
    font-weight: 700;
    font-size: 13px
}

.booking-actions .Active-bttn {
    margin-right: 0
}

.custom-dropdown-show.show {
    display: block;
    position: absolute;
    left: -30%;
    top: 126%;
}

.dropdown-menu.show {
    display: block;
    left: -21%;
    top: 123%;
}

.dashboard-collapse.collapse:not(.show) {
    display: block !important;
}

.dashboard-collapse {
    flex-basis: auto;
    flex-grow: initial;
}



.navbar-nav .dropdown-menu {
    position: absolute;
}

.nav-link {
    margin-right: 6px;
}


@media only screen and (min-width:280px) and (max-width:374px) { 
    .logo-image {
        width: 150px;
        height: auto;
    }

    .nav-link {
        padding-left: 0px!important;
    }

    .nav-link {
        padding-right: 5px!important;
    }

    .Book-Appointment {
        font-size: 16px;
        background-color: #ed1c24!important;
        font-weight: 600;
        border: 1px solid #e83d3e;
        border-radius: 35px;
        color: #fff;
        height: 43px;
        padding: 0 18px;
    }
}



@media (max-width:991px) {
    .dashboard-custom-container {
        margin: 0 20px
    }

    .dashboard-header-bg {
        background-color: #eaeaea;
        margin: 0 30px;
        position: fixed;
        z-index: 999;
        width: -webkit-fill-available;
    }

    .dropdown-menu.show {
        display: block;
        left: -46%;
        top: 125%;
    }

    .dashboard-collapse .navbar-nav {
        display: flex;
        flex-direction: row;
    }
    
    .dashboard-custom-container {
        flex-wrap: initial !important;
    }
}

@media only screen and (min-width:1430px) and (max-width:1440px) {
    .dashboard-header-bg {
        background-color: #eaeaea;
        margin: 0 30px;
        position: fixed;
        z-index: 999;
        width: -webkit-fill-available;
    }

    .profile-btn {
        display: block;
        width: 100%
    }

    .profile-btn button.btn {
        width: 87%;
        margin: 0 10px 20px
    }

    .Profile-details {
        text-align: start;
        margin-bottom: 40px;
        padding-left: 25px
    }


}


@media only screen and (min-width:1200px) and (max-width:1740px) {
    .custom-container {
        margin: 0 80px;
    }

    .review-link .review-link-btn {
        color: #fff;
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 0;
        text-decoration: none;
    }

    
}