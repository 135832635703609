.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #75b317;
    padding: 24px 30px;
}

/* .nav-pills .nav-link.active {
    width: calc(100% + 24px) !important;
} */

.blog-sec-image {
    width: 100%;
}

.nav-pills .nav-link {
    background: #2c2c22;
    padding: 24px 30px;
    border-radius: 30px;
    color: #fff;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    width: 500px !important
}

.nav-pills .nav-link:hover {
    background: #75b317;
    color: #fff
}

.nav-pills .nav-link.active svg {
    color: #fff
}

.nav-pills .nav-link svg {
    margin-right: 20px;
    color: #75b317
}

@media only screen and (min-width:991.98px) and (max-width:1300px) {
    .nav-pills .nav-link {
        background: #2c2c22;
        padding: 16px 15px;
        border-radius: 30px;
        color: #fff;
        margin-bottom: 24px;
        font-size: 20px;
        font-weight: 700
    }

    .nav-pills .nav-link.active {
        padding: 16px 15px
    }

    .nav-pills .nav-link svg {
        margin-right: 10px;
        color: #75b317
    }

    .nav-pills .nav-link {
        width: 330px !important
    }

    .cstm-padding {
        padding: 30px 40px 10px 0px;
    }
}

@media only screen and (min-width:1301px) and (max-width:1650px) {
    .nav-pills .nav-link {
        width: 400px !important
    }

    .nav-pills .nav-link {
        background: #2c2c22;
        padding: 18px 18px;
        border-radius: 30px;
        color: #fff;
        margin-bottom: 24px;
        font-size: 22px;
        font-weight: 700
    }

    .nav-pills .nav-link.active {
        padding: 18px
    }
}


@media only screen and (min-width:1200px) and (max-width:1440px) {
    .blog-short {
        display: block;
    }

    .cstm-padding {
        padding: 0px 0px 30px 0px;
    }

    .blog-short .ms-4 {
        margin-left: 0px !important;
    }

    .our-blog-title {
        margin-top: 14px;
    }

    .our-blog-content p {
        font-size: 18px;
        margin-bottom: 12px;
    }
 }